import { z } from 'zod';
import { useQuizContext } from '../../context/QuizContext';
import { useToast } from '../../../../shadcn/components/ui/use-toast';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../../../shadcn/components/ui/form';
import { ColorPicker } from '../../../../components/forms/inputs/ColorPicker';
import { Button } from '../../../../shadcn/components/ui/button';
import { useState } from 'react';
import { Input } from '../../../../shadcn/components/ui/input';
import { QuizCreateProgressStepDialog } from './QuizCreateProgressStepDialog';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../../../../shadcn/components/ui/table';
import { Pencil } from 'lucide-react';
import { QuizRoadmapStep } from '../../types';
import { QuizEditProgressStepDialog } from './QuizEditProgressStepDialog';
import { putProgressTexts } from '../../../../services/api/quiz/quiz';

const formSchema = z.object({
  descriptionHtmlText: z.string(),
  descriptionHtmlTextColor: z.string(),
  bottomText: z.string(),
  bottomTextColor: z.string(),
});

export const QuizResultProgress = () => {
  const { quiz } = useQuizContext();
  const [selectedStep, setSelectedStep] = useState<QuizRoadmapStep>();
  const [isEditStepDialogOpen, setIsEditStepDialogOpen] = useState(false);

  const { toast } = useToast();
  const queryClient = useQueryClient();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      descriptionHtmlText: quiz?.roadmap.descriptionHtmlText ?? '',
      descriptionHtmlTextColor: quiz?.roadmap.descriptionHtmlTextColor ?? '',
      bottomText: quiz?.answeredPage?.bottomText ?? '',
      bottomTextColor: quiz?.answeredPage?.bottomTextColor ?? '',
    },
  });

  const putQuizProgressTextsMutation = useMutation({
    mutationFn: putProgressTexts,
    onSuccess: async (data) => {
      if (data.success) {
        toast({
          title: 'Textes de roadmap enregistré !',
          description: 'Les changements de texts ont bien été enregistré',
        });

        queryClient.invalidateQueries({ queryKey: ['quiz', quiz?.id] });

        const formValues = form.getValues();

        form.reset(formValues);
      } else {
        toast({
          variant: 'destructive',
          title: "Oops ! Une erreur s'est produite.",
          description:
            "Les changements de textes n'ont pas été enregistré. Veuillez-réessayer.",
        });
      }
    },
  });

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    if (quiz && quiz.answeredPage) {
      putQuizProgressTextsMutation.mutate({
        quizId: quiz.id,
        quizAnsweredPageId: quiz.answeredPage?.id,
        quizRoadmapId: quiz.roadmap.id,
        ...values,
      });
    }
  };

  return (
    <div className="space-y-5">
      <h4 className="text-xl font-light leading-none tracking-tight text-bluePrimary">
        Roadmap
      </h4>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="space-y-5">
            <div className="space-y-3">
              <FormField
                control={form.control}
                name="descriptionHtmlText"
                render={({ field }) => (
                  <FormItem className="grid grid-cols-8 gap-x-4 space-y-0">
                    <FormLabel className="col-span-2 flex items-center justify-start">
                      Texte de description
                    </FormLabel>
                    <FormControl className="col-span-6 col-start-3">
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="descriptionHtmlTextColor"
                render={({ field }) => (
                  <FormItem className="grid grid-cols-8 gap-x-4 space-y-0">
                    <FormLabel className="col-span-2 flex items-center justify-start">
                      Couleur de texte
                    </FormLabel>
                    <FormControl className="col-span-6 text-right">
                      <ColorPicker {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="space-y-3">
              <FormField
                control={form.control}
                name="bottomText"
                render={({ field }) => (
                  <FormItem className="grid grid-cols-8 gap-x-4 space-y-0">
                    <FormLabel className="col-span-2 flex items-center justify-start">
                      Texte jours restant
                    </FormLabel>
                    <FormControl className="col-span-6 col-start-3">
                      <Input {...field} />
                    </FormControl>
                    <ul className="col-span-6 col-start-3 list-inside list-disc pl-2 pt-2 text-xs text-greySecondary">
                      <li>{'${daysLeft}'} - Jours restant</li>
                      <li>{'${questionNumber}'} - Numéro de question</li>
                      <li>
                        {'${questionsLeft}'} - Nombre de questions restantes (
                        <span className="italic">{'jours restant - 1'}</span>)
                      </li>
                    </ul>

                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="bottomTextColor"
                render={({ field }) => (
                  <FormItem className="grid grid-cols-8 gap-x-4 space-y-0">
                    <FormLabel className="col-span-2 flex items-center justify-start">
                      Couleur de texte
                    </FormLabel>
                    <FormControl className="col-span-6 text-right">
                      <ColorPicker {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </div>

          <div className="flex justify-end space-x-4">
            {form.formState.isDirty && (
              <>
                <Button
                  variant="outline"
                  type="button"
                  onClick={() => form.reset()}
                >
                  Réinitialiser
                </Button>

                <Button
                  type="submit"
                  isLoading={putQuizProgressTextsMutation.isPending}
                >
                  Enregistrer
                </Button>
              </>
            )}
          </div>
        </form>
      </Form>
      <div className="flex flex-col space-y-5">
        <div className="flex items-center space-x-4">
          <h4 className="text-xl font-light leading-none tracking-tight text-bluePrimary">
            Étapes
          </h4>

          {quiz && <QuizCreateProgressStepDialog quiz={quiz} />}
          {quiz && selectedStep && isEditStepDialogOpen && (
            <QuizEditProgressStepDialog
              quiz={quiz}
              setIsRoadmapStepEditDialogOpen={setIsEditStepDialogOpen}
              isRoadmapStepEditDialogOpen={isEditStepDialogOpen}
              roadmapStep={selectedStep}
            />
          )}
        </div>

        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="w-[100px]">Points</TableHead>
              <TableHead>Titre</TableHead>
              <TableHead className="w-[100px] text-right">Sous-titre</TableHead>
              <TableHead className="w-[100px]"></TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {quiz?.roadmap.steps
              ?.sort((a, b) => a.value - b.value)
              .map((step) => (
                <TableRow key={step.id}>
                  <TableCell className="font-medium">{step.value}</TableCell>
                  <TableCell>{step.titleHtml}</TableCell>
                  <TableCell className="text-right">
                    {step.subTitleHtml}
                  </TableCell>
                  <TableCell className="text-right">
                    <Button
                      size="icon-sm"
                      variant="outline"
                      onClick={() => {
                        setIsEditStepDialogOpen(true);
                        setSelectedStep(step);
                      }}
                    >
                      <Pencil size={14} />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};
