import axios from 'axios';
import { DateTime } from 'luxon';
import { v4 as uuidv4 } from 'uuid';
import { DroppablePreviewBlocs } from '../../interfaces/droppable';
import { HomeItem } from '../../interfaces/shop';

const AWS_IMAGES_URL = 'https://img.thebradery.com/';

export const getInProgressPreviewData = async (
  customDate?: Date
): Promise<DroppablePreviewBlocs[]> => {
  let data: HomeItem[] = [];

  if (typeof customDate !== 'undefined') {
    data = (await getCustomDateOnGoingSalesData(customDate)).data;
  } else {
    data = (await getOnGoingSalesData()).data;
  }

  const previewData: DroppablePreviewBlocs[] = data.map((element) => {
    return {
      id: uuidv4(),
      content: {
        id: element.id,
        index: element.index,
        blockType: element.blockType,
        date: element.date,
        isDisplayedInallSales: element.isDisplayedInallSales,
        isOnlyDisplayedToDevs: element.isOnlyDisplayedToDevs,
        items: element.items,
      },
    };
  });
  return previewData;
};

export const getUpcomingPreviewData = async () => {
  const rawData = await axios.get('/modular/upcomingSales');

  const previewData = rawData.data.data
    .map((element: any) => {
      const tmpElement = {
        ...element,
        items: element.items.map((item: any) => {
          if (item.expectedDelivery) {
            item.expectedDeliveryDate = DateTime.fromISO(
              item.expectedDelivery
            ).toJSDate();
          }
          return item;
        }),
      };

      return {
        id: uuidv4(),
        content: tmpElement,
      };
    })
    .sort((a: any, b: any) => {
      if (a.content.index > b.content.index) {
        return 1;
      }
      if (a.content.index < b.content.index) {
        return -1;
      }
      return 0;
    });

  return previewData;
};

export const postPreviewData = async (
  type: 'inProgress' | 'upcoming',
  data: any
) => {
  if (type === 'inProgress') {
    await axios.put('/modular/ongoingSales', data);
  } else if (type === 'upcoming') {
    await axios.put('/modular/upcomingSales', data);
  }
};

export const updateItem = async (
  type: 'inProgress' | 'upcoming',
  data: DroppablePreviewBlocs
) => {
  if (type === 'inProgress') {
    return await axios.put('/modular/ongoingSales/item', data);
  } else {
    return await axios.put('/modular/upcomingSales/item', data);
  }
};

export const updateOrderingOngoingSale = async (
  ongoingSalesIdIndexMap: {
    id: number;
    index: number;
  }[]
) => {
  await axios.put('/modular/ongoingSales/ordering', ongoingSalesIdIndexMap);
};

export const updateOrderingUpcomingSales = async (
  upcomingSalesIdIndexMap: {
    id: number | undefined;
    index: number | undefined;
  }[]
) => {
  await axios.put('/modular/upcomingSales/ordering', upcomingSalesIdIndexMap);
};

export const deleteUpcomingSale = async (upcomingSaleId: number) => {
  await axios.delete('/modular/upcomingSales/upcomingSale', {
    data: {
      id: upcomingSaleId,
    },
  });
};

export const uploadFile = async (file: any): Promise<string> => {
  const filename = file.name;
  const extension = filename.slice(filename.lastIndexOf('.') + 1);
  const uploadFile = {
    id: file.name,
    file: file,
    extension: extension,
  };

  const response = await axios.get(`/aws/getS3SignedURL`, {
    params: uploadFile,
  });
  const url = response.data.data.url;
  const path = response.data.data.path;

  await fetch(url, {
    method: 'PUT',
    body: uploadFile.file,
  });

  return `${AWS_IMAGES_URL}${path}`;
};

export const getOnGoingSalesData = async () => {
  const { data } = await axios.get('/modular/ongoingSales/get-all-no-caching');
  return data;
};

export const getCustomDateOnGoingSalesData = async (customDate: Date) => {
  let params = { previewDate: customDate };

  const { data } = await axios.get('/modular/ongoingSales/', { params });
  return data;
};

export interface UploadFileDTO {
  id?: string;
  file?: File;
  extension: string;
}

export interface UploadedFileDTO {
  id?: string;
  path: string;
}
