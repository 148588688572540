import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '../../../../../shadcn/components/tooltip';
import { FormFieldInput } from '../../form/fields/FormFieldInput';
import { FormFieldSwitch } from '../../form/fields/FormFieldSwitch';
import { OngoingSaleFormSchema } from '../../form/schema/OngoingSaleFormSchema';

export const WebpageUniqueOptions = () => {
  const { watch } = useFormContext<OngoingSaleFormSchema>();
  const openInApp = watch('webpage.openInApp');

  return (
    <Container>
      <TooltipProvider>
        <Tooltip delayDuration={100}>
          <TooltipTrigger asChild>
            <div className="w-full">
              <FormFieldSwitch
                fieldName="webpage.openInApp"
                label="URL de la page web"
                classNames={{
                  label: 'font-workSansSemiBold text-bluePrimary',
                }}
                value={openInApp ?? true}
              />
            </div>
          </TooltipTrigger>
          <TooltipContent align="end" alignOffset={-20}>
            <p>Ouvrir dans l'application</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
      <FormFieldInput
        type="text"
        fieldName="webpage.url"
        placeholder="Entrez l'url..."
        classNames={{
          container: 'flex flex-col items-center w-full',
          label: 'font-workSansSemiBold text-bluePrimary',
          input:
            'font-workSansSemiBold border-greyPrimary  rounded-md bg-beigePrimary px-2 py-1 text-blueSecondary focus-visible:outline-blueSecondary focus-visible:ring-1',
        }}
        cancelNativeChange
        onChange={(field, value) => {
          field.onChange(value.trim());
          field.onBlur();
        }}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 10px;
  width: 100%;
`;
