import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { Separator } from '../../../../shadcn/components/separator';
import { Form } from '../../../../shadcn/components/ui/form';
import {
  Tabs,
  TabsContent,
  TabsList,
} from '../../../../shadcn/components/ui/tabs';
import { isOngoingSale } from '../../../../utils/typguards/isOngoingSale';
import { useHomePageContext } from '../../context/HomepageContext';
import { ongoingSaleMutation } from '../../rest/post/postOngoingSale';
import {
  ONGOING_SALE_FORM_SCHEMA,
  OngoingSaleFormSchema,
} from './schema/OngoingSaleFormSchema';
import { OngoingSale, SaleDataType } from '../../types';
import { createOngoingSaleDTOFromSchema } from '../../utils/createOngoingSaleDTOFromSchema';
import { SaleOptionCategories } from '../sale_options/SaleOptionCategories';
import { SaleOptionDates } from '../sale_options/SaleOptionDates';
import { SaleOptionDiscount } from '../sale_options/SaleOptionDiscount';
import { SaleOptionMediasUploader } from '../sale_options/SaleOptionMediasUploader';
import { SaleOptionTypes } from '../sale_options/SaleOptionTypes';
import { SaleUniqueOptions } from '../sale_options/unique_options/SaleUniqueOptions';
import { SaleFormFooter } from './SaleFormFooter';
import { SaleFormHeader } from './SaleFormHeader';
import { deleteOngoingSaleMutation } from '../../rest/delete/deleteOngoingSale';
import { reorderSaleMutation } from '../../rest/post/postReorderingSale';
import { DND_ONGOING_SALE_PREVIEW_ID } from '../../constants';

type OngoingSaleFormProps = {
  ongoingSale: OngoingSale;
};

export const OngoingSaleForm = ({ ongoingSale }: OngoingSaleFormProps) => {
  const context = useHomePageContext();
  const salePostMutation = useMutation(ongoingSaleMutation());
  const saleDeleteMutation = useMutation(
    deleteOngoingSaleMutation(ongoingSale.isLegacySale)
  );
  const reorderPostMutation = useMutation(reorderSaleMutation());

  const [isDelete, setIsDelete] = useState(false);

  const form = useForm<OngoingSaleFormSchema>({
    resolver: zodResolver(ONGOING_SALE_FORM_SCHEMA),
    mode: 'onSubmit',
    defaultValues: {
      homepageImages:
        ongoingSale.homepageImages.length === 0
          ? [{ url: undefined }]
          : ongoingSale.homepageImages,
      dataType: ongoingSale.dataType,
      categories: ongoingSale.categories.map((category) => ({
        id: category.id,
      })),
      isDisplayedInAllSales: ongoingSale.isDisplayedInAllSales,
      isOnlyDisplayedToDevs: ongoingSale.isOnlyDisplayedToDevs,
      dateStart: ongoingSale.dateStart,
      dateStop: ongoingSale.dateStop,
      discount: {
        allAt: ongoingSale.discountAllAt ?? null,
        upTo: ongoingSale.discountUpTo ?? null,
      },
      collection: {
        shopifyId: ongoingSale.collection?.shopifyId,
        collectionImage: ongoingSale.collection?.collectionImage?.url,
      },
      webpage: ongoingSale.webpage,
      product: ongoingSale.product,
    },
  });

  useEffect(() => {
    context.setIsFormDirty(form.formState.isDirty);
  }, [context, form.formState.isDirty]);

  const onSubmit: SubmitHandler<OngoingSaleFormSchema> = async (
    data,
    e?: React.BaseSyntheticEvent
  ) => {
    // This prevents the page from reloading when the form is submitted
    e?.preventDefault();

    if (!isOngoingSale(context.selectedSale)) {
      return;
    }

    const onMutationSuccess = () => {
      form.reset();
      context.setIsFormOpen(false);
      setTimeout(() => context.setSelectedSale(undefined), 150);

      // The sale was added, so we need to reorder the other ones
      if (ongoingSale.id < 0 || isDelete) {
        reorderPostMutation.mutate({
          containerId: DND_ONGOING_SALE_PREVIEW_ID,
          dto: context.draggableAllSales
            .filter(({ sale }) => sale.id !== ongoingSale.id)
            .map(({ sale }, index) => ({
              id: sale.id,
              isLegacySale: sale.isLegacySale,
              index: isDelete ? index : sale.index,
            })),
        });
      }
    };

    if (isDelete) {
      saleDeleteMutation.mutate(ongoingSale.id, {
        onSuccess: onMutationSuccess,
      });
    } else {
      const dto = createOngoingSaleDTOFromSchema(data, context.selectedSale);
      salePostMutation.mutate(dto, {
        onSuccess: onMutationSuccess,
      });
    }
  };

  const onFormError: SubmitErrorHandler<OngoingSaleFormSchema> = (data) => {};

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit, onFormError)}
        className="space-y-4"
      >
        <SaleFormHeader blockType={ongoingSale.blockType} />
        <FullWidthSeparator />

        <Tabs
          defaultValue={ongoingSale.dataType}
          onValueChange={() => form.clearErrors()}
          className="relative space-y-4"
        >
          <TabsList className="flex h-auto">
            <SaleOptionTypes />
          </TabsList>

          <FullWidthSeparator />

          {Object.values(SaleDataType).map((dataType) => (
            <TabsContent
              className="m-0 flex flex-col gap-y-4"
              key={dataType}
              value={dataType}
            >
              <SaleOptionMediasUploader blockType={ongoingSale.blockType} />

              {dataType !== SaleDataType.STATIC && <FullWidthSeparator />}

              <SaleUniqueOptions ongoingSale={ongoingSale} />
              <FullWidthSeparator />

              <SaleOptionCategories />
              <FullWidthSeparator />

              <SaleOptionDates />
              <FullWidthSeparator />

              <SaleOptionDiscount />
              <FullWidthSeparator />
            </TabsContent>
          ))}

          <SaleFormFooter
            isFromBackend={(ongoingSale.id ?? -1) >= 0}
            setIsDelete={setIsDelete}
          />
        </Tabs>
      </form>
    </Form>
  );
};

const FullWidthSeparator = styled(Separator)`
  height: 0.25em;
  width: calc(100% + 4rem);
  margin-left: -2rem;
`;
